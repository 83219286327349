import { Box, Typography } from '@mui/material'
import React from 'react'

/**
 * 状態を表示するラベルの型
 */
export interface StatusLabelProps {
  /**
   * ラベルのタイトル
   */
  labelTitle: string
  /**
   * ラベルのテキスト
   */
  labelText: string
}

/**
 * 状態を表示するラベル（選択中の検索条件等）
 */
export const StatusLabel = ({ labelTitle, labelText }: StatusLabelProps) => {
  return (
    <Box sx={{ display: 'inline-block', '& + &': { ml: 2 } }}>
      <Typography
        variant="caption"
        sx={{
          display: 'inline-block',
          verticalAlign: 'middle',
          mr: 1,
          p: '2px 4px',
          border: '1px solid',
          borderRadius: '4px',
          borderColor: 'info.dark',
          backgroundColor: '#e3f2fd',
        }}
      >
        {labelTitle}
      </Typography>
      <Typography variant="body2" sx={{ display: 'inline-block' }}>
        {labelText}
      </Typography>
    </Box>
  )
}
