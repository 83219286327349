import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InformationEditForm } from '../components/domain/InformationEditForm'
import { useEditInformationMutation } from '../ducks/information/informationApi'
import {
  Information,
  InformationEditData,
} from '../ducks/information/informationInterface'
// import {
//   useDeleteUserMutation,
//   useEditUserMutation,
// } from '../ducks/users/usersApi'

export const InformationEditPage = () => {
  const navigate = useNavigate()
  // お知らせ一覧で選択されたお知らせ情報
  const { state } = useLocation() as { state: Information }
  const [editInformation] = useEditInformationMutation()
  // const [deleteUser] = useDeleteUserMutation()

  // 編集するデータ
  const editData: InformationEditData = useMemo(
    () => ({
      id: state.id,
      title: state.title,
      status: state.status,
      startDate: state.startDate,
      url: state.url,
    }),
    [state]
  )

  // お知らせ登録時処理
  const handleSubmit = useCallback(
    (formData: InformationEditData) => {
      editInformation({ id: state.id, ...formData })
      navigate('/information')
    },
    [editInformation, navigate, state.id]
  )

  // お知らせ削除時処理
  const handleUserDelete = useCallback(() => {
    // deleteUser({
    //   id: state.id,
    //   name: state.name,
    // })
    navigate('/information')
  }, [navigate])

  return (
    <InformationEditForm
      editData={editData}
      onSubmitForm={handleSubmit}
      onDeleteInformation={handleUserDelete}
    />
  )
}
