import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { adminApi } from './adminApi'
import auth from './auth/authSlice'
import dataStatusListPage from './dataStatus/dataStatusListPageSlice'
import userListPage from './users/userListPageSlice'

/**
 * Reducer
 */
const reducer = combineReducers({
  auth,
  [adminApi.reducerPath]: adminApi.reducer,
  userListPage,
  dataStatusListPage,
})

/**
 * Store （カスタマイズする場合はここを触る）
 */
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // MEMO: 事情によりgetAccessTokenSilentlySelectorをstoreに突っ込んでいるのでシリアライズチェックを無効
      serializableCheck: {
        ignoredActions: ['auth/setAccessTokenSilently'],
        ignoredPaths: ['auth'],
      },
    }).concat(adminApi.middleware),
})

/**
 * RootStateの型
 */
export type RootState = ReturnType<typeof store.getState>

/**
 * Dispatchの型
 */
export type AppDispatch = typeof store.dispatch
