import { Box, Container, Grid, Paper, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Header } from './Header'
import { Sidebar } from './Sidebar'

export interface PageLayoutProps {
  /**
   * ページタイトル
   */
  pageTitle: string
  /**
   * タイトル横のボタン配置用エリア
   */
  actionButtonArea?: React.ReactNode
  /**
   * ページ幅を制限しない（幅を無制限にする）
   */
  fullWidth?: boolean
  children: React.ReactNode
}

/**
 * ページレイアウトのテンプレート
 */
export const PageLayout: React.FC<PageLayoutProps> = (props) => {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 72px)',
          }}
        >
          <Toolbar />
          <Paper
            elevation={0}
            square
            sx={{
              bgcolor: 'primary.light',
              color: 'primary.contrastText',
              py: 3,
            }}
          >
            <PageTitle {...props} />
          </Paper>
          <Box sx={{ flex: 1 }}>{props.children}</Box>
        </Box>
      </Box>
    </>
  )
}

/**
 * ページタイトル部分
 */
const PageTitle = (props: PageLayoutProps) => {
  // 幅の制限をしない
  if (props.fullWidth) {
    return (
      <Box sx={{ px: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item sx={{ flex: 1 }}>
            <Typography component="h1" variant="h4">
              {props.pageTitle}
            </Typography>
          </Grid>
          <Grid>{props.actionButtonArea}</Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Container fixed>
      <Grid container spacing={2} alignItems="center">
        <Grid item sx={{ flex: 1 }}>
          <Typography component="h1" variant="h4">
            {props.pageTitle}
          </Typography>
        </Grid>
        <Grid>{props.actionButtonArea}</Grid>
      </Grid>
    </Container>
  )
}
