import { Button, Stack, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { DataStatusDataGrid } from '../components/domain/DataStatusDataGrid'
import {
  GroupSearchDialog,
  HallGroupItem,
} from '../components/domain/GroupSearchDialog'
import { PageLayout } from '../components/layouts/PageLayout'
import { PageWrapper } from '../components/layouts/PageWrapper'
import { StatusLabel } from '../components/ui/StatusLabel'
import {
  dataStatusSelectGroupSelector,
  dataStatusSelectHallSelector,
} from '../ducks/dataStatus/dataStatusListPageSelector'
import {
  resetSelectedData,
  setSelectGroup,
  setSelectHall,
} from '../ducks/dataStatus/dataStatusListPageSlice'

/**
 * 受信状況確認ページ
 */
export const DataStatusPage = () => {
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState<undefined | 'group' | 'hall'>()
  const selectGroup = useSelector(dataStatusSelectGroupSelector, shallowEqual)
  const selectHall = useSelector(dataStatusSelectHallSelector, shallowEqual)

  // 企業選択ボタン押下時の処理
  const handleOpenGroupDialog = () => {
    setDialogOpen('group')
  }

  // 店舗選択ボタン押下時の処理
  const handleOpenHallDialog = () => {
    setDialogOpen('hall')
  }

  // 企業選択ダイアログ閉じる処理
  const handleCloseDialog = () => {
    setDialogOpen(undefined)
  }

  // 選択条件クリアボタン
  const handleReset = () => {
    dispatch(resetSelectedData())
  }

  // 企業・店舗選択時の処理
  const handleDialogSelect = useCallback(
    (item: HallGroupItem) => {
      setDialogOpen(undefined)

      if (dialogOpen === 'hall') {
        return dispatch(setSelectHall(item))
      }

      dispatch(setSelectGroup(item))
    },
    [dialogOpen, dispatch]
  )

  return (
    <PageLayout pageTitle="受信状況確認" fullWidth>
      <PageWrapper fullWidth>
        <Stack direction="row" spacing={3} mb={3} alignItems="center">
          <Button
            variant="contained"
            size="large"
            onClick={handleOpenGroupDialog}
          >
            企業選択
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleOpenHallDialog}
          >
            店舗選択
          </Button>

          <div>
            {/* 企業・店舗未選択時 */}
            {!selectGroup && !selectHall && (
              <Typography>企業または店舗を選択してください</Typography>
            )}
            {/* 企業選択時 */}
            {selectGroup && (
              <>
                <StatusLabel
                  labelTitle="共有グループコード"
                  labelText={selectGroup.groupCode}
                />
                <StatusLabel
                  labelTitle="企業名"
                  labelText={selectGroup.groupName}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleReset}
                  sx={{ ml: 2 }}
                >
                  選択リセット
                </Button>
              </>
            )}
            {/* 店舗選択時 */}
            {selectHall && (
              <>
                <StatusLabel
                  labelTitle="店舗コード"
                  labelText={selectHall.hallCode}
                />
                <StatusLabel
                  labelTitle="店舗名"
                  labelText={selectHall.hallName}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleReset}
                  sx={{ ml: 2 }}
                >
                  選択リセット
                </Button>
              </>
            )}
          </div>
        </Stack>
        <DataStatusDataGrid
          groupCode={selectGroup?.groupCode}
          hallCode={selectHall?.hallCode}
        />
        <GroupSearchDialog
          open={!!dialogOpen}
          onSelectGroup={handleDialogSelect}
          onCancel={handleCloseDialog}
        />
      </PageWrapper>
    </PageLayout>
  )
}
