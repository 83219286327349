import { adminApi } from '../adminApi'
import { InformationEditData, InformationList } from './informationInterface'

/**
 * インフォーメション用エンドポイント
 */
export const informationEndpoints = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    // インフォメーション一覧を取得
    getInformationList: builder.query<InformationList, void>({
      query: () => {
        return { url: 'information' }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Information' as const,
                id,
              })),
              { type: 'Information', id: 'LIST' },
            ]
          : [{ type: 'Information', id: 'LIST' }],
    }),
    // お知らせ新規登録
    addInformation: builder.mutation<{}, InformationEditData>({
      query: (params) => {
        return {
          url: 'information',
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['Information'],
    }),
    // お知らせ編集
    editInformation: builder.mutation<{}, InformationEditData>({
      query: ({ id, ...params }) => {
        return {
          url: `information/${id}`,
          method: 'PUT',
          body: params,
        }
      },
      invalidatesTags: ['Information'],
    }),
  }),
})

/**
 * データ利用用Hooks
 */
export const {
  useGetInformationListQuery,
  useAddInformationMutation,
  useEditInformationMutation,
} = informationEndpoints
