import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HallGroupItem } from '../../components/domain/GroupSearchDialog'
import { usersEndpoints } from './usersApi'

/**
 * ユーザー一覧ページのStateの型
 */
interface UserListPageState {
  /**
   * 選択中の企業（ページ遷移しても保持するようにする）
   */
  selectGroup?: HallGroupItem
  /**
   * Snackbarに表示する内容
   */
  snackbar?: {
    /**
     * Snackbarの種類（成功・エラー）
     */
    type: string
    /**
     * Snackbarのメッセージ
     */
    message: string
  }
}

/**
 * ユーザー一覧ページのStateの初期値
 */
const initialState: UserListPageState = {
  selectGroup: undefined,
  snackbar: undefined,
}

/**
 * ユーザー一覧ページのSlice
 */
export const userListPageSlice = createSlice({
  name: 'userListPage',
  initialState,
  reducers: {
    setSelectGroup: (state, { payload }: PayloadAction<HallGroupItem>) => {
      state.selectGroup = payload
    },
    closeSnackbar: (state) => {
      state.snackbar = undefined
    },
  },
  extraReducers: (builder) => {
    // ユーザー新規登録完了時
    builder.addMatcher(
      usersEndpoints.endpoints.addUser.matchFulfilled,
      (state, { meta }) => {
        state.snackbar = {
          type: 'success',
          message: `ユーザー「${meta.arg.originalArgs.name}」を登録しました。`,
        }
      }
    )
    // ユーザー新規登録失敗時
    builder.addMatcher(
      usersEndpoints.endpoints.addUser.matchRejected,
      (state, { meta }) => {
        state.snackbar = {
          type: 'error',
          message: `ユーザー「${meta.arg.originalArgs.name}」の登録に失敗しました。`,
        }
      }
    )
    // ユーザー更新完了時
    builder.addMatcher(
      usersEndpoints.endpoints.editUser.matchFulfilled,
      (state, { meta }) => {
        state.snackbar = {
          type: 'success',
          message: `ユーザー「${meta.arg.originalArgs.name}」を更新しました。`,
        }
      }
    )
    // ユーザー更新失敗時
    builder.addMatcher(
      usersEndpoints.endpoints.editUser.matchRejected,
      (state, { meta }) => {
        state.snackbar = {
          type: 'error',
          message: `ユーザー「${meta.arg.originalArgs.name}」の更新に失敗しました。`,
        }
      }
    )
    // ユーザー削除完了時
    builder.addMatcher(
      usersEndpoints.endpoints.deleteUser.matchFulfilled,
      (state, { meta }) => {
        state.snackbar = {
          type: 'success',
          message: `ユーザー「${meta.arg.originalArgs.name}」を削除しました。`,
        }
      }
    )
    // ユーザー削除失敗時
    builder.addMatcher(
      usersEndpoints.endpoints.deleteUser.matchRejected,
      (state, { meta }) => {
        state.snackbar = {
          type: 'error',
          message: `ユーザー「${meta.arg.originalArgs.name}」の削除に失敗しました。`,
        }
      }
    )
  },
})

/**
 * ユーザー一覧ページのActions
 */
export const { setSelectGroup, closeSnackbar } = userListPageSlice.actions

/**
 * ユーザー一覧ページのReducer
 */
export default userListPageSlice.reducer
