import { adminApi } from '../adminApi'
import { User, UserEditData, UserList, UserListParams } from './usersInterface'

/**
 * ユーザー用エンドポイント
 */
export const usersEndpoints = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    // ユーザー一覧を取得
    getUserList: builder.query<UserList, UserListParams>({
      query: (params) => {
        return {
          url: 'users',
          params,
        }
      },
      providesTags: () => [{ type: 'Users' }],
    }),
    // ユーザー新規登録
    addUser: builder.mutation<{}, UserEditData>({
      query: (params) => {
        return {
          url: 'users',
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['Users'],
    }),
    // ユーザー編集
    editUser: builder.mutation<
      {},
      Omit<UserEditData, 'groupCode'> & Pick<User, 'id'>
    >({
      query: ({ id, ...params }) => {
        return {
          url: `users/${id}`,
          method: 'PUT',
          body: params,
        }
      },
      invalidatesTags: ['Users'],
    }),
    // ユーザー削除
    deleteUser: builder.mutation<{}, Pick<User, 'id' | 'name'>>({
      query: ({ id }) => {
        return {
          url: `users/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['Users'],
    }),
  }),
})

/**
 * データ利用用Hooks
 */
export const {
  useGetUserListQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
} = usersEndpoints
