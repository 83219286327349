import { CircularProgress, Container, Paper, Typography } from '@mui/material'

export const LoadingPage = () => {
  return (
    <Container
      fixed
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper elevation={8} sx={{ textAlign: 'center', px: 6, py: 4 }}>
        <Typography variant="body1" align="center" sx={{ mb: 2 }}>
          読み込み中
        </Typography>
        <CircularProgress />
      </Paper>
    </Container>
  )
}
