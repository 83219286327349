import { createTheme } from '@mui/material'
import { jaJP as coreJaJP } from '@mui/material/locale'
import { ThemeProvider } from '@mui/styles'
import { DataGrid, GridColumns, GridRowParams, jaJP } from '@mui/x-data-grid'
import { useGetUserListQuery } from '../../ducks/users/usersApi'
import { User } from '../../ducks/users/usersInterface'

/**
 * ユーザーのpropsの型
 */
export interface UserDataGridProps {
  /**
   * 絞り込むグループコード
   */
  groupCode: string
  /**
   * ユーザー選択時の処理
   */
  onSelectUser?: (user: User) => void
}

/**
 * ユーザー一覧
 */
export const UserDataGrid = ({
  groupCode,
  onSelectUser,
}: UserDataGridProps) => {
  const { data, isLoading } = useGetUserListQuery({
    groupCode,
  })

  const theme = createTheme({}, jaJP, coreJaJP)

  // データグリッドのヘッダー行
  const gridColumns: GridColumns = [
    {
      field: 'roleName',
      headerName: '区分',
      width: 80,
    },
    {
      field: 'email',
      headerName: 'ユーザーID',
      width: 260,
    },
    {
      field: 'name',
      headerName: '利用者名',
      width: 180,
    },
    {
      field: 'groupCode',
      headerName: '共有グループコード',
      width: 160,
    },
  ]

  // ユーザー選択時の処理
  const handleSelect = (param: GridRowParams) => {
    if (!onSelectUser) return

    onSelectUser(param.row as User)
  }

  return (
    <ThemeProvider theme={theme}>
      <DataGrid
        loading={isLoading}
        columns={gridColumns}
        rows={data?.data ?? []}
        disableSelectionOnClick
        autoHeight
        onRowClick={handleSelect}
      />
    </ThemeProvider>
  )
}
