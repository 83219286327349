import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { DataGrid, GridColumns, GridRowParams } from '@mui/x-data-grid'
import React, { useMemo, useState } from 'react'
import { useGetGroupListQuery } from '../../ducks/groups/groupApi'
import { Group } from '../../ducks/groups/groupsInterface'

/**
 * 企業選択ダイアログのpropsの型
 */
export interface GroupSearchDialogProps {
  /**
   * 企業選択ダイアログの開閉状態
   */
  open: boolean
  /**
   * 企業選択時の処理
   */
  onSelectGroup: (groupItem: HallGroupItem) => void
  /**
   * 企業選択キャンセル時の処理
   */
  onCancel: () => void
}

/**
 * 企業選択用に正規化した企業情報
 */
export interface HallGroupItem extends Group {
  id: string
  hallCode: string
  hallName: string
}

/**
 * 企業選択ダイアログ
 */
export const GroupSearchDialog = ({
  open,
  onSelectGroup,
  onCancel,
}: GroupSearchDialogProps) => {
  const { data, isLoading } = useGetGroupListQuery()

  const [searchGroupName, setSearchGroupName] = useState('')
  const [searchHallName, setSearchHallName] = useState('')

  // DataGridのヘッダー行
  const hallGroupColumns: GridColumns = [
    {
      field: 'groupCode',
      headerName: '共有グループコード',
      width: 200,
    },
    {
      field: 'groupName',
      headerName: '企業名',
      width: 200,
    },
    {
      field: 'hallName',
      headerName: '店舗名',
      width: 200,
    },
  ]

  // DataGridのデータ行
  const hallGroupRows = useMemo(() => {
    // データが未取得の場合は空のarrayを返す
    if (!data?.searchCondition.companies) return []

    // 検索＆DataGrid用にデータを正規化
    return data.searchCondition.companies.reduce(
      (acc: HallGroupItem[], group) => {
        group.halls.map((hall) => {
          // 企業名検索で一致しない行を排除
          if (searchGroupName !== '') {
            if (!group.groupName.match(searchGroupName)) return undefined
          }
          // 店舗名検索で一致しない行を排除
          if (searchHallName !== '') {
            if (!hall.name.match(searchHallName)) return undefined
          }

          // DataGrid用に正規化したデータを生成
          return acc.push({
            id: `${group.groupCode}${hall.code}`,
            ...group,
            hallCode: hall.code,
            hallName: hall.name,
          })
        })

        return acc
      },
      []
    )
  }, [data?.searchCondition.companies, searchGroupName, searchHallName])

  // 企業選択時の処理
  const handleSelect = (param: GridRowParams) => {
    onSelectGroup(param.row as HallGroupItem)
    setSearchGroupName('')
    setSearchHallName('')
  }

  // 企業選択キャンセル時の処理
  const handleCancel = () => {
    onCancel()
    setSearchGroupName('')
    setSearchHallName('')
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      sx={{
        [`& .MuiPaper-root`]: {
          overflowY: 'unset',
          height: '100%',
          maxHeight: 860,
        },
      }}
      onClose={handleCancel}
    >
      <DialogTitle>企業選択</DialogTitle>
      <DialogContent
        sx={{ overflowY: 'unset', display: 'flex', flexDirection: 'column' }}
      >
        {/* 企業一覧検索 */}
        <Typography variant="h6" gutterBottom>
          企業一覧検索
        </Typography>
        <DialogContentText gutterBottom>
          キーワードを入力すると企業一覧を絞り込むことができます。
        </DialogContentText>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item md={6}>
            <TextField
              id="groupNameSearch"
              label="企業名"
              variant="outlined"
              size="small"
              fullWidth
              value={searchGroupName}
              onChange={(event) => setSearchGroupName(event.target.value)}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="hallNameSearch"
              label="店舗名"
              variant="outlined"
              size="small"
              fullWidth
              value={searchHallName}
              onChange={(event) => setSearchHallName(event.target.value)}
            />
          </Grid>
        </Grid>
        {/* 企業一覧 */}
        <Typography variant="h6" gutterBottom>
          企業一覧
        </Typography>
        <DialogContentText gutterBottom>
          企業一覧をクリックして企業を選択してください。
        </DialogContentText>
        <Box sx={{ flex: 1 }}>
          <DataGrid
            loading={isLoading}
            columns={hallGroupColumns}
            rows={hallGroupRows}
            disableSelectionOnClick
            autoPageSize
            onRowClick={handleSelect}
          />
        </Box>
      </DialogContent>
      {/* ボタン類 */}
      <DialogActions>
        <Button onClick={handleCancel}>キャンセル</Button>
      </DialogActions>
    </Dialog>
  )
}
