import { createTheme, ThemeOptions } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'

const defaultStyle: ThemeOptions = {
  spacing: 8,
  palette: {
    primary: {
      main: '#1D2088',
      dark: '#14165F',
      light: '#4A4C9F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F39700',
      dark: '#BD7500',
      light: '#FFB63D',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: '#E2E0DE',
    info: {
      light: '#4DB4F5',
      main: '#21A2F3',
      dark: '#1771AA',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
    },
    warning: {
      main: '#FFD500',
      dark: '#B29500',
      light: '#FFDD33',
    },
    success: {
      main: '#59CC5D',
      dark: '#3D8C40',
      light: '#95E598',
    },
    grey: {
      50: '#FBFAF8',
      100: '#F5F5F5',
      200: '#EFEDEA',
      300: '#E2E0DE',
      400: '#C0BDBA',
      500: '#A29F9A',
      600: '#7A7670',
      700: '#65625D',
      800: '#45433F',
      900: '#222120',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: 'rgb(226, 224, 222)',
          '& .MuiDataGrid-columnHeaders': {
            borderBottomColor: 'rgb(226, 224, 222)',
          },
          '& .MuiDataGrid-cell': {
            borderBottomColor: 'rgb(226, 224, 222)',
          },
        },
      },
    },
  },
}

export default createTheme({
  ...defaultStyle,
})
