import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Stack } from '@mui/material'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataStatusDetailDataGrid } from '../components/domain/DataStatusDetailDataGrid'
import { PageLayout } from '../components/layouts/PageLayout'
import { PageWrapper } from '../components/layouts/PageWrapper'
import { StatusLabel } from '../components/ui/StatusLabel'
import { DataStatusDetailListParams } from '../ducks/dataStatus/dataStatusInterface'
interface DataStatusDetailPageProps extends DataStatusDetailListParams {
  hallName?: string
}

/**
 * データ受信状況詳細ページ
 */
export const DataStatusDetailPage = () => {
  const navigate = useNavigate()
  const { state } = useLocation() as { state: DataStatusDetailPageProps }
  const { hallName, ...detailListParams } = state

  // データ受信状況一覧に戻る
  const handleBackDataStatusList = useCallback(() => {
    navigate('/dataStatus')
  }, [navigate])

  return (
    <PageLayout
      pageTitle="受信状況詳細"
      actionButtonArea={
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="warning"
            startIcon={<ArrowBackIcon />}
            onClick={handleBackDataStatusList}
          >
            一覧に戻る
          </Button>
        </Stack>
      }
      fullWidth
    >
      <PageWrapper fullWidth>
        <Stack direction="row" spacing={3} mb={3} alignItems="center">
          <StatusLabel labelTitle="日付" labelText={state.date ?? ''} />
          <StatusLabel
            labelTitle="PH会員コード"
            labelText={state.hallCode ?? ''}
          />
          <StatusLabel labelTitle="店舗名" labelText={hallName ?? ''} />
          <StatusLabel
            labelTitle="データ種別"
            labelText={state.dataType ?? ''}
          />
        </Stack>
        <DataStatusDetailDataGrid {...detailListParams} />
      </PageWrapper>
    </PageLayout>
  )
}
