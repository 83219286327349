import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

/**
 * 汎用的な確認ダイアログの型
 */
export interface ConfirmDialogProps {
  /**
   * ダイアログの開閉状態
   */
  open: boolean
  /**
   * ダイアログのタイトル
   */
  title: string
  /**
   * ダイアログの本文
   */
  contentText: string
  /**
   * ダイアログを閉じる処理
   */
  onClose?: () => void
  /**
   * ダイアログでOKボタンを押した時の処理
   */
  onApply?: () => void
}

/**
 * 汎用的な確認ダイアログ
 */
export const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>キャンセル</Button>
        <Button onClick={props.onApply}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}
