import React, { FC } from 'react'

/**
 * タブパネルの型
 */
export interface TabPanelProps {
  /**
   * タブのインデックス
   */
  tabIndex: number | string
  /**
   * 選択中のタブのインデックス
   */
  value: number | string
  children: React.ReactNode
}

/**
 * タブパネル
 */
export const TabPanel: FC<TabPanelProps> = ({ tabIndex, value, children }) => {
  if (tabIndex !== value) return null

  return <>{children}</>
}
