import { Typography } from '@mui/material'
import { PageLayout } from '../components/layouts/PageLayout'
import { PageWrapper } from '../components/layouts/PageWrapper'

/**
 * ダッシュボードページ
 */
export const DashboardPage = () => {
  return (
    <PageLayout pageTitle="ダッシュボード">
      <PageWrapper fullWidth>
        <Typography variant="body1">ダッシュボードです。</Typography>
      </PageWrapper>
    </PageLayout>
  )
}
