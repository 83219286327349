import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from './store'

/**
 * 管理用API
 */
export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST,
    prepareHeaders: async (headers, { getState }) => {
      const getAccessTokenSilently = (getState() as RootState).auth
        .getAccessTokenSilently

      // アクセストークンをヘッダーに含める
      if (getAccessTokenSilently) {
        const token = await getAccessTokenSilently()
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Users',
    'Group',
    'Information',
    'DataStatus',
    'DataStatusDetail',
    'PresignedUrls',
  ],
})
