import { Box, CircularProgress, createTheme } from '@mui/material'
import { jaJP as coreJaJP } from '@mui/material/locale'
import { ThemeProvider } from '@mui/styles'
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridCsvExportOptions,
  GridToolbar,
  jaJP,
} from '@mui/x-data-grid'
import { format, parse } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetDataStatusListQuery } from '../../ducks/dataStatus/dataStatusApi'
import {
  DataStatus,
  DataStatusListParams,
} from '../../ducks/dataStatus/dataStatusInterface'

export const DataStatusDataGrid = (props: DataStatusListParams) => {
  const navigate = useNavigate()

  const theme = createTheme({}, jaJP, coreJaJP)

  const { data, isLoading } = useGetDataStatusListQuery({ ...props })

  // 受信状況の日付部分を含めた行データに正規化
  const dataStatusRow =
    data?.data.map((item) => {
      const { dataStatus, ...other } = item

      // 日付部分をDataGridで実現できるように正規化
      const row = dataStatus.reduce((prev, current, i) => {
        return {
          ...prev,
          [`day${i}`]: current.status,
        }
      }, {})

      return {
        // MEMO: DataGridでidが必要なためここで追加
        id: `${other.hallCode}${other.dataType}`,
        ...row,
        ...other,
      }
    }) ?? []

  // 日付部分のヘッダー行データを生成
  const dataStatusColumns: GridColumns = useMemo(
    () =>
      data?.data
        .slice(0)
        .shift()
        ?.dataStatus.map((status, i) => {
          const date = parse(status.date, 'yyyy-MM-dd', new Date())
          return {
            field: `day${i}`,
            headerName: format(date, 'M/d'),
            width: 70,
            align: 'center',
            cellClassName: (param: GridCellParams<string>) => {
              if (param.value !== 'NG') return ''
              return 'ng'
            },
          }
        }) ?? [],
    [data?.data]
  )

  // 全てのcolumnsデータ（日付部分を含む）
  const gridColumns: GridColumns = [
    {
      field: 'groupCode',
      headerName: 'グループコード',
      width: 130,
    },
    {
      field: 'groupName',
      headerName: '企業名',
      width: 200,
    },
    {
      field: 'hallCode',
      headerName: 'PH会員コード',
      width: 130,
    },
    {
      field: 'hallName',
      headerName: '店舗名',
      width: 300,
    },
    {
      field: 'dataType',
      headerName: 'データ種類',
      width: 140,
    },
    ...dataStatusColumns,
    {
      field: 'updatedAt',
      headerName: '最終受信日時',
      width: 140,
      align: 'center',
      valueFormatter: (param) => {
        if (param.value === '-') return param.value
        if (!param.value) return '-'
        const date = parse(
          String(param.value),
          'yyyy-MM-dd HH:mm:ss',
          new Date()
        )
        return format(date, 'yyyy/MM/dd HH:mm:ss')
      },
    },
  ]

  // 日付セルをクリック時詳細ページに遷移
  const handleDataCellClick = useCallback(
    (field: string, row: DataStatus) => {
      const { hallCode, hallName, dataType } = row

      // 受信状況セルの場合のみ詳細ページに移動する
      if (dataStatusColumns.find((item) => item.field === field)) {
        // クリックされた日付セルのindexを取得する
        const index = dataStatusColumns.findIndex(
          (item) => item.field === field
        )
        // クリックされた日付セルの日付を取得
        const date = data?.data
          .slice(0)
          .shift()
          ?.dataStatus.slice(index)
          .shift()
        if (!date) return

        // 詳細ページに遷移
        navigate('/dataStatus/detail', {
          state: { hallCode, hallName, dataType, date: date.date },
        })
      }
    },
    [data?.data, dataStatusColumns, navigate]
  )

  // CSV出力オプション
  const csvOptions: GridCsvExportOptions = {
    utf8WithBom: true,
    fileName: `受信状況確認_${format(Date.now(), 'yyyyMMddHHmm')}`,
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          checkboxSelection
          columns={gridColumns}
          rows={dataStatusRow}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{ toolbar: { csvOptions } }}
          disableSelectionOnClick
          onCellClick={(event) => handleDataCellClick(event.field, event.row)}
          sx={{
            '& .MuiDataGrid-cell.ng': {
              bgcolor: 'error.light',
            },
            '& .MuiDataGrid-cell--textCenter': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-cell--textCenter: hover': {
              color: 'blue',
            },
          }}
        />
      )}
    </ThemeProvider>
  )
}
