import { useAuth0 } from '@auth0/auth0-react'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessTokenSilentlySelector } from '../../ducks/auth/authSelector'
import { setAccessTokenSilently } from '../../ducks/auth/authSlice'
import { LoadingPage } from '../../pages/LoadingPage'

type Props = {
  children: React.ReactNode
}

/**
 * ログインされていてアクセストークンの取得が完了したタイミングで子コンポーネントをマウントする
 */
export const AuthenticationRequired: FC<Props> = ({ children }) => {
  const reduxTokenSelector = useSelector(getAccessTokenSilentlySelector)
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0()
  const dispatch = useDispatch()

  // ログインされていない場合はユニバーサルログインに遷移
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return
    }

    ;(async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      })
    })()
  }, [isAuthenticated, isLoading, loginWithRedirect])

  // getAccessTokenSilentlySelectorをReduxに渡す
  useEffect(() => {
    dispatch(setAccessTokenSilently(getAccessTokenSilently))
  }, [dispatch, getAccessTokenSilently])

  // ログインできていないまたはアクセストークン取得待ちの場合ローディングを表示
  if (!isAuthenticated || !reduxTokenSelector) return <LoadingPage />

  // 認証情報に問題がない場合のみ子コンポーネントをマウントする
  return <>{children}</>
}
