import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { App } from './App'
import { store } from './ducks/store'
import reportWebVitals from './reportWebVitals'
import theme from './theme'

const main = async () => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MSW === 'true'
  ) {
    const { setupWorker } = await import('msw')
    const { handlers } = await import('./mocks/handlers')

    const worker = setupWorker(...handlers)
    await worker.start({ onUnhandledRequest: 'bypass' })
  }

  const container = document.getElementById('root')
  if (!container) throw new Error('Failed to find the root element')
  const root = ReactDOM.createRoot(container)

  root.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      redirectUri={`${window.location.protocol}//${window.location.host}`}
      audience={process.env.REACT_APP_API_HOST}
      cacheLocation="memory"
      useRefreshTokens
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  )
}
main()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
