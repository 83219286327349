import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { InformationEditForm } from '../components/domain/InformationEditForm'
import { useAddInformationMutation } from '../ducks/information/informationApi'
import { InformationEditData } from '../ducks/information/informationInterface'

export const InformationAddPage = () => {
  const navigate = useNavigate()
  const [addInformation] = useAddInformationMutation()

  // ユーザー登録時処理
  const handleSubmit = useCallback(
    (formData: InformationEditData) => {
      addInformation(formData)
      navigate('/information')
    },
    [addInformation, navigate]
  )

  return <InformationEditForm onSubmitForm={handleSubmit} />
}
