import { RootState } from '../store'

/**
 * データ受信状況一覧ページで選択中の企業情報を取得
 * @returns 選択中の企業
 */
export const dataStatusSelectGroupSelector = (state: RootState) =>
  state.dataStatusListPage.selectGroup

/**
 * データ受信状況一覧ページで選択中の店舗情報を取得
 * @returns 選択中の店舗
 */
export const dataStatusSelectHallSelector = (state: RootState) =>
  state.dataStatusListPage.selectHall
