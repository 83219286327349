import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  GroupSearchDialog,
  HallGroupItem,
} from '../components/domain/GroupSearchDialog'
import { UserDataGrid } from '../components/domain/UserDataGrid'
import { PageLayout } from '../components/layouts/PageLayout'
import { PageWrapper } from '../components/layouts/PageWrapper'
import { StatusLabel } from '../components/ui/StatusLabel'
import {
  selectGroupSelector,
  snackbarSelector,
} from '../ducks/users/userListPageSelector'
import { closeSnackbar, setSelectGroup } from '../ducks/users/userListPageSlice'
import { User } from '../ducks/users/usersInterface'

/**
 * ユーザー一覧ページ
 */
export const UserListPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [dialogOpen, setDialogOpen] = useState(false)

  // 選択中の企業
  const selectGroup = useSelector(selectGroupSelector, shallowEqual)
  // Snackbarの内容
  const snackbar = useSelector(snackbarSelector, shallowEqual)

  // 企業選択を表示
  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true)
  }, [])

  // 企業選択を非表示
  const handleDialogCancel = useCallback(() => {
    setDialogOpen(false)
  }, [])

  // 企業選択で企業を選択
  const handleDialogSelect = useCallback(
    (groupItem: HallGroupItem) => {
      setDialogOpen(false)
      dispatch(setSelectGroup(groupItem))
      setSelectGroup(groupItem)
    },
    [dispatch]
  )

  // ユーザー新規登録画面に遷移
  const handleUserAdd = useCallback(() => {
    navigate('/users/add')
  }, [navigate])

  // ユーザー編集画面に遷移
  const handleUserEdit = useCallback(
    (user: User) => {
      navigate('/users/edit', { state: user })
    },
    [navigate]
  )

  // Snackbarを閉じる
  const handleCloseSnackbar = useCallback(() => {
    dispatch(closeSnackbar())
  }, [dispatch])

  // ページ離脱時にSnackbarを無効化する
  useEffect(() => {
    return () => {
      handleCloseSnackbar()
    }
  }, [handleCloseSnackbar])

  return (
    <PageLayout
      pageTitle="ユーザー一覧"
      actionButtonArea={
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={handleUserAdd}
        >
          新規登録
        </Button>
      }
    >
      <PageWrapper fullWidth>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Button variant="contained" size="large" onClick={handleDialogOpen}>
              企業選択
            </Button>
          </Grid>
          <Grid item>
            {/* 選択中のグループ情報 */}
            <Box sx={{ px: 2, py: '10px' }}>
              <StatusLabel
                labelTitle="共有グループコード"
                labelText={selectGroup?.groupCode ?? '選択なし'}
              />
              <StatusLabel
                labelTitle="企業名"
                labelText={selectGroup?.groupName ?? '選択なし'}
              />
            </Box>
          </Grid>
        </Grid>
        {/* ユーザー一覧 */}
        {selectGroup && (
          <Box sx={{ mt: 6 }}>
            <UserDataGrid
              groupCode={selectGroup.groupCode}
              onSelectUser={handleUserEdit}
            />
          </Box>
        )}
        {/* 企業選択 */}
        <GroupSearchDialog
          open={dialogOpen}
          onSelectGroup={handleDialogSelect}
          onCancel={handleDialogCancel}
        />
        {/* APIリクエスト完了・失敗を通知 */}
        <Snackbar
          open={!!snackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Paper elevation={3}>
            <Alert
              severity={(snackbar?.type as AlertColor) ?? 'info'}
              onClose={handleCloseSnackbar}
            >
              {snackbar?.message}
            </Alert>
          </Paper>
        </Snackbar>
      </PageWrapper>
    </PageLayout>
  )
}
