import { adminApi } from '../../adminApi'
import { FetchS3DataList } from '../dataStatusInterface'

/**
 * データ受信状況詳細用エンドポイント
 */
export const dataStatusDetailEndpoints = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    // データ受信状況詳細S3署名付きURL取得
    getPresignedUrls: builder.query<FetchS3DataList, any>({
      query: (params) => {
        return {
          url: 'signedUrls',
          params,
        }
      },
      providesTags: () => [{ type: 'PresignedUrls' }],
    }),
  }),
})

/**
 * 詳細データ利用用Hooks
 */
export const { useLazyGetPresignedUrlsQuery } = dataStatusDetailEndpoints
