import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Button, createTheme } from '@mui/material'
import { jaJP as coreJaJP } from '@mui/material/locale'
import { ThemeProvider } from '@mui/styles'
import {
  DataGrid,
  GridColumns,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  jaJP,
} from '@mui/x-data-grid'
import queryString from 'query-string'
import { useState } from 'react'
import { useGetDataStatusDetailListQuery } from '../../ducks/dataStatus/dataStatusApi'
import {
  DataStatusDetail,
  DataStatusDetailListParams,
} from '../../ducks/dataStatus/dataStatusInterface'
import { useLazyGetPresignedUrlsQuery } from '../../ducks/dataStatus/detail/dataStatusDetailApi'

export const DataStatusDetailDataGrid = (props: DataStatusDetailListParams) => {
  const theme = createTheme({}, jaJP, coreJaJP)

  const [selectionModel, setSelectionModel] = useState<DataStatusDetail[]>([])
  const { data } = useGetDataStatusDetailListQuery(
    { ...props },
    { refetchOnMountOrArgChange: true }
  )
  const [trigger] = useLazyGetPresignedUrlsQuery()

  // 署名付きURL発行
  const handleSubmit = async () => {
    // チェックボックスで選択された行のS3ファイル名を取得、およびフォーマット
    const formatedPresignedUrls: string[] = selectionModel.map(
      (item: DataStatusDetail) => item.s3Uri ?? []
    )
    const s3UrlsQueryStrings: string = queryString.stringify(
      { urls: formatedPresignedUrls },
      { arrayFormat: 'index' }
    )
    // S3署名付きURLの発行
    const res = await trigger(s3UrlsQueryStrings, true)
    // S3ファイル群ダウンロード
    res.data &&
      res.data.urls &&
      res.data.urls.map(async (url: string) => {
        await window.open(url)
      })
  }

  // DataGridのカラム
  const gridColumns: GridColumns = [
    {
      field: 'dataDate',
      headerName: 'データ日付',
      width: 150,
    },
    {
      field: 'registeredAt',
      headerName: '連携時刻',
      width: 150,
    },
    {
      field: 'hallName',
      headerName: '店舗名',
      width: 200,
    },
    {
      field: 'dataLoaderType',
      headerName: '通信種別',
      width: 200,
    },
    {
      field: 'dataType',
      headerName: 'データ種類',
      width: 200,
    },
    {
      field: 'status',
      headerName: '処理結果',
      width: 100,
    },
    {
      field: 's3Uri',
      headerName: '受信ファイル格納場所',
      width: 800,
    },
    {
      field: 'errorMessage',
      headerName: 'エラーメッセージ',
      width: 600,
    },
  ]

  // IDをとりあえず付与
  const dataRow =
    data?.data.map((item, i) => ({
      id: `${item.dataDate}${i}`,
      ...item,
    })) ?? []

  // CSV出力オプション
  const csvOptions: GridCsvExportOptions = {
    utf8WithBom: true,
  }

  // 表上部に表示されるカスタムバー
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button onClick={handleSubmit} startIcon={<CloudDownloadIcon />}>
          ファイルダウンロード
        </Button>
      </GridToolbarContainer>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <DataGrid
        checkboxSelection
        columns={gridColumns}
        rows={dataRow}
        components={{ Toolbar: CustomToolbar }}
        onSelectionModelChange={(columnsId) => {
          // 選択された行を特定するための処理
          const selectedColumnsId = new Set(columnsId)
          const selectedRowData = dataRow.filter((dataGridRow) =>
            selectedColumnsId.has(dataGridRow.id.toString())
          )
          setSelectionModel(selectedRowData)
        }}
        componentsProps={{ toolbar: { csvOptions } }}
        disableSelectionOnClick
        autoHeight
      />
    </ThemeProvider>
  )
}
