import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserEditForm } from '../components/domain/UserEditForm'
import {
  useDeleteUserMutation,
  useEditUserMutation,
} from '../ducks/users/usersApi'
import { User, UserEditData } from '../ducks/users/usersInterface'

export const UserEditPage = () => {
  const navigate = useNavigate()
  // ユーザー一覧で選択されたユーザー情報
  const { state } = useLocation() as { state: User }
  const [editUser] = useEditUserMutation()
  const [deleteUser] = useDeleteUserMutation()

  // 編集するデータ
  const editData: UserEditData = useMemo(
    () => ({
      roleCode: state.roleCode,
      groupCode: state.groupCode,
      email: state.email,
      name: state.name,
    }),
    [state]
  )

  // ユーザー登録時処理
  const handleSubmit = useCallback(
    (formData: UserEditData) => {
      // MEMO: 編集時は共有グループコードを変更できないようにする
      const { groupCode, ...params } = formData
      editUser({
        id: state.id,
        ...params,
      })
      navigate('/users')
    },
    [editUser, navigate, state.id]
  )

  // ユーザー削除時処理
  const handleUserDelete = useCallback(() => {
    deleteUser({
      id: state.id,
      name: state.name,
    })
    navigate('/users')
  }, [deleteUser, navigate, state.id, state.name])

  return (
    <UserEditForm
      editData={editData}
      onSubmitForm={handleSubmit}
      onDeleteUser={handleUserDelete}
    />
  )
}
