import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import PublishIcon from '@mui/icons-material/Publish'
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Tab,
  TabsProps,
  TextField,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useGetGroupListQuery } from '../../ducks/groups/groupApi'
import { UserEditData } from '../../ducks/users/usersInterface'
import { GroupSearchDialog, HallGroupItem } from '../domain/GroupSearchDialog'
import { PageLayout } from '../layouts/PageLayout'
import { PageTabs } from '../layouts/PageTabs'
import { PageWrapper } from '../layouts/PageWrapper'
import { TabPanel } from '../layouts/TabPanel'
import { ConfirmDialog } from '../ui/ConfirmDialog'
import { StatusLabel } from '../ui/StatusLabel'

export interface UserEditFormProps {
  /**
   * 編集するフォームデータ
   */
  editData?: UserEditData
  /**
   * フォーム送信時の処理
   */
  onSubmitForm: (formData: UserEditData) => void
  /**
   * ユーザー削除時の処理
   */
  onDeleteUser?: () => void
}

/**
 * バリデーションのルール
 */
const schema = yup.object({
  roleCode: yup.string().required('ユーザー区分が選択されていません。'),
  groupCode: yup.string().required('所属企業が選択されていません。'),
  email: yup
    .string()
    .email('正しいユーザーIDを入力してください。')
    .required('ユーザーIDは必須項目です。'),
  name: yup.string().required('利用者名は必須項目です。'),
})

/**
 * ユーザー編集フォーム
 */
export const UserEditForm = ({
  editData,
  onSubmitForm,
  onDeleteUser,
}: UserEditFormProps) => {
  const navigate = useNavigate()
  const { data, isLoading } = useGetGroupListQuery()
  const { register, handleSubmit, formState, control, setValue } =
    useForm<UserEditData>({
      resolver: yupResolver(schema),
    })

  const [tabIndex, setTabIndex] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectGroup, setSelectGroup] = useState<HallGroupItem | undefined>()

  // 編集フォームか新規フォームか（true時編集フォーム）
  const isEdit = useMemo(() => !!editData, [editData])

  // フォームのタイトル
  const pageTitle = useMemo(
    () => (isEdit ? 'ユーザー編集' : 'ユーザー新規登録'),
    [isEdit]
  )

  // ユーザー一覧に戻る
  const handleBackUserList = useCallback(() => {
    navigate('/users')
  }, [navigate])

  // 企業選択を表示
  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true)
  }, [])

  // 企業選択を非表示
  const handleDialogCancel = useCallback(() => {
    setDialogOpen(false)
  }, [])

  // 企業選択で企業を選択
  const handleDialogSelect = useCallback(
    (groupItem: HallGroupItem) => {
      setDialogOpen(false)
      setSelectGroup(groupItem)
      setValue('groupCode', groupItem.groupCode, { shouldValidate: true })
    },
    [setValue]
  )

  // ユーザー削除確認ダイアログを開く
  const handleOpenDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(true)
  }, [])

  // ユーザー削除確認ダイアログを閉じる
  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false)
  }, [])

  // タブ切り替え時
  const handleTabChange: TabsProps['onChange'] = (_, index) => {
    setTabIndex(index)
  }

  // フォーム送信時処理
  const onSubmit: SubmitHandler<UserEditData> = (data) => onSubmitForm(data)

  // 編集データから企業選択を行う
  useEffect(() => {
    const companies = data?.searchCondition.companies

    // データが空の場合処理を行わない
    if (!companies || !editData) return

    // 選択中の企業を取得
    const company = companies.find(
      (item) => item.groupCode === editData.groupCode
    )

    // 編集データに該当する企業がある場合は企業選択を行う
    if (company) {
      handleDialogSelect({
        ...company,
        // MEMO: 使用しないので適当な値をセット
        id: company.groupCode,
        hallCode: '',
        hallName: '',
      })
    }
  }, [data?.searchCondition.companies, editData, handleDialogSelect])

  return (
    <PageLayout
      pageTitle={pageTitle}
      actionButtonArea={
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="warning"
            startIcon={<ArrowBackIcon />}
            onClick={handleBackUserList}
          >
            一覧に戻る
          </Button>
          <Button
            variant="contained"
            color="info"
            startIcon={<PublishIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {isEdit ? '更新' : '登録'}
          </Button>
        </Stack>
      }
    >
      <PageTabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="基本情報" />
        {/* MEMO: ユーザー削除は編集時のみ有効にする */}
        {isEdit && <Tab label="設定" />}
      </PageTabs>
      <PageWrapper>
        <TabPanel value={tabIndex} tabIndex={0}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ mb: '1em' }}
          >
            ユーザー情報
          </Typography>
          <Paper elevation={3}>
            {/* 所属企業選択 */}
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                所属企業
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                {isEdit
                  ? '利用者が所属している企業情報です。'
                  : '利用者の所属する企業を選択してください。'}
              </Typography>
              {isLoading && <CircularProgress />}
              {!isLoading && (
                <Stack spacing={3} direction="row">
                  {/* MEMO: 編集時は所属企業を変更できないので企業選択自体を非表示 */}
                  {!isEdit && (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleDialogOpen}
                    >
                      企業選択
                    </Button>
                  )}
                  {/* 選択中のグループ情報 */}
                  <Box sx={{ py: '10px' }}>
                    <StatusLabel
                      labelTitle="共有グループコード"
                      labelText={selectGroup?.groupCode ?? '選択なし'}
                    />
                    <StatusLabel
                      labelTitle="企業名"
                      labelText={selectGroup?.groupName ?? '選択なし'}
                    />
                  </Box>
                </Stack>
              )}
              <input {...register('groupCode')} type="hidden" />
              {/* 企業選択エラー表示 */}
              {'groupCode' in formState.errors && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ color: 'error.main', mt: '1em' }}
                >
                  {formState.errors.groupCode?.message}
                </Typography>
              )}
            </Box>
            <Divider />
            <Box p={2}>
              <Typography variant="subtitle1" gutterBottom>
                ユーザー区分
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                利用者のユーザー区分を選択してください。
              </Typography>
              <Controller
                name="roleCode"
                defaultValue={editData?.roleCode ?? ''}
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    value={field.value}
                    onChange={(_, v) => field.onChange(v)}
                  >
                    <FormControlLabel
                      value="admin"
                      control={<Radio />}
                      label="管理者"
                    />
                    <FormControlLabel
                      value="maintenance"
                      control={<Radio />}
                      label="保守"
                    />
                    {!!editData && (
                      <FormControlLabel
                        value="general"
                        control={<Radio />}
                        label="一般"
                      />
                    )}
                  </RadioGroup>
                )}
              />
              {'roleCode' in formState.errors && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ color: 'error.main' }}
                >
                  {formState.errors.roleCode?.message}
                </Typography>
              )}
            </Box>
            <Divider />
            {/* ユーザーID */}
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                ユーザーID
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                利用者のユーザーID（メールアドレス）を入力してください。
              </Typography>
              <TextField
                size="small"
                fullWidth
                {...register('email')}
                type="email"
                defaultValue={editData?.email ?? ''}
                placeholder="example@example.com"
                error={'email' in formState.errors}
                helperText={formState.errors.email?.message}
              />
            </Box>
            <Divider />
            {/* 利用者名 */}
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                利用者名
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                利用者のお名前を入力してください。
              </Typography>
              <TextField
                size="small"
                fullWidth
                {...register('name')}
                defaultValue={editData?.name ?? ''}
                placeholder="名前太郎"
                error={'name' in formState.errors}
                helperText={formState.errors.name?.message}
              />
            </Box>
          </Paper>
          {/* 企業選択 */}
          <GroupSearchDialog
            open={dialogOpen}
            onSelectGroup={handleDialogSelect}
            onCancel={handleDialogCancel}
          />
        </TabPanel>
        {/* MEMO: ユーザー削除は編集時のみ有効にする */}
        {isEdit && (
          <TabPanel value={tabIndex} tabIndex={1}>
            <Alert variant="outlined" severity="error">
              <AlertTitle sx={{ mb: 1 }}>破壊的変更</AlertTitle>
              <Stack spacing={2}>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteForeverIcon />}
                  onClick={handleOpenDeleteDialog}
                >
                  ユーザーを削除する
                </Button>
              </Stack>
            </Alert>
            <ConfirmDialog
              open={deleteDialogOpen}
              title="ユーザー削除の確認"
              contentText={`ユーザー「${editData?.name}」を削除します。よろしいですか？`}
              onApply={onDeleteUser}
              onClose={handleCloseDeleteDialog}
            />
          </TabPanel>
        )}
      </PageWrapper>
    </PageLayout>
  )
}
