/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { createTheme } from '@mui/material'
import { jaJP as coreJaJP } from '@mui/material/locale'
import { ThemeProvider } from '@mui/styles'
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
  jaJP,
} from '@mui/x-data-grid'
import { format } from 'date-fns'
import { useState } from 'react'
import { useGetInformationListQuery } from '../../ducks/information/informationApi'
import { InformationData } from '../../ducks/information/informationInterface'
/**
 * ユーザーのpropsの型
 */
export interface InformationDataGridProps {
  /**
   * インフォメーション選択時の処理
   */
  onSelectInformation?: (information: InformationData) => void
}

const theme = createTheme({}, jaJP, coreJaJP)

const dateTimeGenerator = (value: string) => {
  return format(new Date(String(value)), 'yyyy-MM-dd HH:mm')
}

const columns: GridColumns = [
  {
    field: 'status',
    headerName: '状態',
    width: 120,
    headerClassName: 'info-header',
    cellClassName: (params: GridCellParams<string>) => {
      return params.value === '公開' ? 'positive' : 'negative'
    },
  },
  {
    field: 'createdAt',
    headerName: '作成日時',
    width: 180,
    valueFormatter: ({ value }) =>
      value ? dateTimeGenerator(value.toString()) : '',
  },
  {
    field: 'startDate',
    headerName: '公開日時',
    width: 180,
    type: 'string',
    valueFormatter: ({ value }) =>
      value ? dateTimeGenerator(value.toString()) : '',
    cellClassName: (params: GridCellParams<string>) => {
      return new Date(params.value?.toString() ?? '') < new Date()
        ? 'negative'
        : 'positive'
    },
  },
  {
    field: 'title',
    headerName: '表示テキスト',
    width: 420,
  },
]

export const InformationDataGrid = ({
  onSelectInformation,
}: InformationDataGridProps) => {
  const { data, isLoading } = useGetInformationListQuery()

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'startDate',
      sort: 'desc' as GridSortDirection,
    },
  ])

  // ユーザー選択時の処理
  const handleSelect = (param: GridRowParams) => {
    if (!onSelectInformation) return

    onSelectInformation(param.row as InformationData)
  }

  if (isLoading) return null
  if (!data) return null

  // APIから受けたデータを加工
  const rowData = data.data.map((item) => {
    return {
      ...item,
      status: item.isPublished ? '公開' : '未公開',
    }
  })

  return (
    <div style={{ width: '100%' }}>
      <ThemeProvider theme={theme}>
        <DataGrid
          sortModel={sortModel}
          rows={rowData ?? []}
          columns={columns}
          autoHeight
          sortingOrder={['asc', 'desc']}
          onSortModelChange={(model) => setSortModel(model)}
          onRowClick={handleSelect}
          getRowClassName={(params) => `row-style-${params.id}`}
          css={infoHeader}
        />
      </ThemeProvider>
    </div>
  )
}

const infoHeader = css`
  .positive {
    font-weight: bold;
  }
`
