import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HallGroupItem } from '../../components/domain/GroupSearchDialog'

/**
 * データ受信状況一覧ページのStateの型
 */
interface DataStatusListPageState {
  selectGroup?: HallGroupItem
  selectHall?: HallGroupItem
}

/**
 * データ受信状況一覧ページのStateの初期値
 */
const initialState: DataStatusListPageState = {
  selectGroup: undefined,
  selectHall: undefined,
}

/**
 * データ受信状況一覧ページのSlice
 */
export const dataStatusListPageSlice = createSlice({
  name: 'dataStatusListPage',
  initialState,
  reducers: {
    setSelectGroup: (state, { payload }: PayloadAction<HallGroupItem>) => {
      state.selectGroup = payload
      state.selectHall = undefined
    },
    setSelectHall: (state, { payload }: PayloadAction<HallGroupItem>) => {
      state.selectGroup = undefined
      state.selectHall = payload
    },
    resetSelectedData: () => {
      return initialState
    },
  },
})

/**
 * データ受信状況一覧ページのActions
 */
export const { setSelectGroup, setSelectHall, resetSelectedData } =
  dataStatusListPageSlice.actions

/**
 * データ受信状況一覧ページのReducer
 */
export default dataStatusListPageSlice.reducer
