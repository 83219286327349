import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PublishIcon from '@mui/icons-material/Publish'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  TabsProps,
  TextField,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import {
  InformationEditData,
  Status,
} from '../../ducks/information/informationInterface'
import { PageLayout } from '../layouts/PageLayout'
import { PageTabs } from '../layouts/PageTabs'
import { PageWrapper } from '../layouts/PageWrapper'
import { TabPanel } from '../layouts/TabPanel'

export interface InformationEditFormProps {
  /**
   * 編集するフォームデータ
   */
  editData?: InformationEditData
  /**
   * フォーム送信時の処理
   */
  onSubmitForm: (formData: InformationEditData) => void
  /**
   * インフォメーション削除時の処理
   */
  onDeleteInformation?: () => void
}

/**
 * バリデーションのルール
 */
const schema = yup.object({
  title: yup
    .string()
    .max(200, '200文字以内で登録をしてください。')
    .required('表示テキストは必須項目です。'),
  url: yup.string().url('正しいURLの形式で入力してください。'),
  status: yup.string().required('公開状態を選択してください。'),
  startDate: yup.string().when('status', {
    is: '公開予約',
    then: yup.string().required('公開予約の場合は日時を指定してください。'),
  }),
})

/**
 * インフォーメション編集フォーム
 */
export const InformationEditForm = ({
  editData,
  onSubmitForm,
}: InformationEditFormProps) => {
  const navigate = useNavigate()
  const { register, handleSubmit, formState } = useForm<InformationEditData>({
    resolver: yupResolver(schema),
  })

  const [tabIndex, setTabIndex] = useState(0)

  // 編集フォームか新規フォームか（true時編集フォーム）
  const isEdit = useMemo(() => !!editData, [editData])

  // フォームのタイトル
  const pageTitle = useMemo(
    () => (isEdit ? 'お知らせ編集' : 'お知らせ新規登録'),
    [isEdit]
  )

  // お知らせ一覧に戻る
  const handleBackInformationList = useCallback(() => {
    navigate('/information')
  }, [navigate])

  // タブ切り替え時
  const handleTabChange: TabsProps['onChange'] = (_, index) => {
    setTabIndex(index)
  }

  // フォーム送信時処理
  const onSubmit: SubmitHandler<InformationEditData> = (data) => {
    // 公開フラグ
    data.isPublished = data.status === '公開'

    if (data.url === '') {
      data.url = null
    }

    // 公開予定日時をISO形式に加工
    if (data.startDate) {
      data.startDate = new Date(data.startDate).toISOString()
    } else {
      data.startDate = new Date().toISOString()
    }

    onSubmitForm(data)
  }

  const [status, setStatus] = React.useState(editData?.status)

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as Status)
  }

  return (
    <PageLayout
      pageTitle={pageTitle}
      actionButtonArea={
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="warning"
            startIcon={<ArrowBackIcon />}
            onClick={handleBackInformationList}
          >
            一覧に戻る
          </Button>
          <Button
            variant="contained"
            color="info"
            startIcon={<PublishIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {isEdit ? '更新' : '登録'}
          </Button>
        </Stack>
      }
    >
      <PageTabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="基本情報" />
      </PageTabs>
      <PageWrapper>
        <TabPanel value={tabIndex} tabIndex={0}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ mb: '1em' }}
          >
            お知らせ情報
          </Typography>
          <Paper elevation={3}>
            {/* 利用者名 */}
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                表示テキスト
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                お知らせエリアに表示するテキストを入力してください。
              </Typography>
              <TextField
                size="small"
                multiline
                fullWidth
                {...register('title')}
                defaultValue={editData?.title ?? ''}
                placeholder="メンテナンスのお知らせ"
                error={'title' in formState.errors}
                helperText={formState.errors.title?.message}
              />
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                リンク先URL
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                お知らせをクリックして遷移するリンク先のURLを入力してください。
              </Typography>
              <TextField
                size="small"
                fullWidth
                {...register('url')}
                defaultValue={editData?.url ?? ''}
                placeholder="https://clarislink-support.com"
                error={'url' in formState.errors}
                helperText={formState.errors.url?.message}
              />
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                公開/未公開状態
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                公開・未公開から選択してください。「公開」を選択したお知らせがお知らせエリアに表示されます。
              </Typography>
              <FormControl
                sx={{ minWidth: 120 }}
                error={'status' in formState.errors}
              >
                <Select
                  {...register('status')}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                  size="small"
                  value={status}
                  error={'status' in formState.errors}
                  defaultValue="未公開"
                >
                  <MenuItem value="公開">公開</MenuItem>
                  <MenuItem value="未公開">未公開</MenuItem>
                </Select>
                <FormHelperText>
                  {formState.errors.status?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                公開日時
              </Typography>
              <Typography variant="body2" color="GrayText" sx={{ mb: 2 }}>
                公開時間を設定したい場合は、日時を指定してください。未設定の場合は現在時間が設定されます。
              </Typography>
              <TextField
                id="datetime-local"
                type="datetime-local"
                {...register('startDate')}
                defaultValue={
                  editData?.startDate && editData.startDate !== '-'
                    ? format(new Date(editData.startDate), "yyyy-MM-dd'T'HH:mm")
                    : null
                }
                size="small"
                sx={{ width: 250 }}
                helperText={formState.errors.startDate?.message}
                error={'startDate' in formState.errors}
              />
            </Box>
          </Paper>
        </TabPanel>
      </PageWrapper>
    </PageLayout>
  )
}
