import { Box, Container } from '@mui/material'
import React, { FC } from 'react'

export interface PageWrapperProps {
  /**
   * ページ幅を制限しない（幅を無制限にする）
   */
  fullWidth?: boolean
  children: React.ReactNode
}

/**
 * ページ本体をレイアウト用
 */
export const PageWrapper: FC<PageWrapperProps> = (props) => {
  // 幅の制限をしない
  if (props.fullWidth) {
    return <Box sx={{ pt: 7, px: 3, height: '100%' }}>{props.children}</Box>
  }

  return (
    <Container fixed sx={{ pt: 7, height: '100%' }}>
      {props.children}
    </Container>
  )
}
