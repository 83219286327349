import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { InformationDataGrid } from '../components/domain/InformationDataGrid'
import { PageLayout } from '../components/layouts/PageLayout'
import { PageWrapper } from '../components/layouts/PageWrapper'
import { Information } from '../ducks/information/informationInterface'
import { useAppDispatch } from '../ducks/lib/hooks'
import { closeSnackbar } from '../ducks/users/userListPageSlice'

/**
 * お知らせ一覧ページ
 */
export const InformationListPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // お知らせ新規登録画面に遷移
  const handleUserAdd = useCallback(() => {
    navigate('/information/add')
  }, [navigate])

  // お知らせ編集画面に遷移
  const handleInformationEdit = useCallback(
    (information: Information) => {
      navigate('/information/edit', { state: information })
    },
    [navigate]
  )

  // Snackbarを閉じる
  const handleCloseSnackbar = useCallback(() => {
    dispatch(closeSnackbar())
  }, [dispatch])

  // ページ離脱時にSnackbarを無効化する
  useEffect(() => {
    return () => {
      handleCloseSnackbar()
    }
  }, [handleCloseSnackbar])

  return (
    <PageLayout
      pageTitle="お知らせ一覧"
      actionButtonArea={
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={handleUserAdd}
        >
          新規登録
        </Button>
      }
    >
      <PageWrapper fullWidth>
        {/* お知らせ一覧 */}
        <Box sx={{ mt: 6 }}>
          <InformationDataGrid onSelectInformation={handleInformationEdit} />
        </Box>
      </PageWrapper>
    </PageLayout>
  )
}
