import { Container, Paper, Tabs, TabsProps } from '@mui/material'
import { FC } from 'react'

/**
 * ページタイトル下のタブ
 */
export const PageTabs: FC<TabsProps> = ({ children, ...rest }) => {
  return (
    <Paper
      elevation={0}
      square
      sx={{
        bgcolor: 'primary.light',
        color: 'primary.contrastText',
        py: 0,
      }}
    >
      <Container fixed>
        <Tabs
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'white',
            },
            '& .MuiButtonBase-root': {
              color: 'white',
            },
            '& .MuiButtonBase-root.Mui-selected': {
              color: 'white',
            },
          }}
          {...rest}
        >
          {children}
        </Tabs>
      </Container>
    </Paper>
  )
}
