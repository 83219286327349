import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthenticationRequired } from './components/functional/AuthenticationRequired'
import { DashboardPage } from './pages/DashboardPage'
import { DataStatusDetailPage } from './pages/DataStatusDetailPage'
import { DataStatusPage } from './pages/DataStatusPage'
import { HashPage } from './pages/HashPage'
import { InformationAddPage } from './pages/InformationAddPage'
import { InformationEditPage } from './pages/InformationEditPage'
import { InformationListPage } from './pages/InformationListPage'
import { LoadingPage } from './pages/LoadingPage'
import { UserAddPage } from './pages/UserAddPage'
import { UserEditPage } from './pages/UserEditPage'
import { UserListPage } from './pages/UserListPage'

/**
 * アプリケーションのルート
 */
export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthenticationRequired>
              <DashboardPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="users"
          element={
            <AuthenticationRequired>
              <UserListPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="users/add"
          element={
            <AuthenticationRequired>
              <UserAddPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="users/edit"
          element={
            <AuthenticationRequired>
              <UserEditPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="information"
          element={
            <AuthenticationRequired>
              <InformationListPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="information/add"
          element={
            <AuthenticationRequired>
              <InformationAddPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="information/edit"
          element={
            <AuthenticationRequired>
              <InformationEditPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="dataStatus"
          element={
            <AuthenticationRequired>
              <DataStatusPage />
            </AuthenticationRequired>
          }
        />
        <Route
          path="dataStatus/detail"
          element={
            <AuthenticationRequired>
              <DataStatusDetailPage />
            </AuthenticationRequired>
          }
        />
        <Route path="loading" element={<LoadingPage />} />
        <Route path="_hash" element={<HashPage />} />
      </Routes>
    </BrowserRouter>
  )
}
