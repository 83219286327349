import { useAuth0 } from '@auth0/auth0-react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {
  AppBar,
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverProps,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'

/**
 * アプリケーションのヘッダー
 */
export const Header = () => {
  const { user, logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>()

  // Popoverを開く
  const handleOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  // Popoverを閉じる
  const handleClosePopover = () => {
    setAnchorEl(undefined)
  }

  // Auth0をログアウトする
  const handleLogout = useCallback(() => {
    logout({
      returnTo: `${window.location.protocol}//${window.location.host}`,
    })
  }, [logout])

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Claris Admin
        </Typography>
        <IconButton color="inherit" onClick={handleOpenPopover}>
          <Avatar alt={user?.nickname ?? ''} src={user?.picture ?? ''} />
        </IconButton>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleClosePopover}
        >
          {user && (
            <>
              <List>
                <ListItem>
                  <ListItemText primary={user.name} />
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          <List>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItem>
          </List>
        </Popover>
      </Toolbar>
    </AppBar>
  )
}
