import { RootState } from '../store'

/**
 * ユーザー一覧ページで選択中の企業情報を取得
 * @returns 選択中の企業
 */
export const selectGroupSelector = (state: RootState) =>
  state.userListPage.selectGroup

/**
 * Snackbarの内容を取得する
 * @returns Snackbarの内容
 */
export const snackbarSelector = (state: RootState) =>
  state.userListPage.snackbar
