import { adminApi } from '../adminApi'
import { GroupList } from './groupsInterface'

/**
 * ユーザー用エンドポイント
 */
const groupsEndpoints = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    // ユーザー一覧を取得
    getGroupList: builder.query<GroupList, void>({
      query: () => {
        return {
          url: 'settings/options/company',
        }
      },
      providesTags: () => [{ type: 'Group' }],
    }),
  }),
})

/**
 * データ利用用Hooks
 */
export const { useGetGroupListQuery } = groupsEndpoints
