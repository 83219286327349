import { adminApi } from '../adminApi'
import {
  DataStatusDetailList,
  DataStatusDetailListParams,
  DataStatusList,
  DataStatusListParams,
} from './dataStatusInterface'

/**
 * データ受信状況用エンドポイント
 */
export const dataStatusEndpoints = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    // データ受信状況一覧を取得
    getDataStatusList: builder.query<DataStatusList, DataStatusListParams>({
      query: (params) => {
        return {
          url: 'dataReceptionStatus',
          params,
        }
      },
      providesTags: () => [{ type: 'DataStatus' }],
    }),
    // データ受信状況詳細を取得
    getDataStatusDetailList: builder.query<
      DataStatusDetailList,
      DataStatusDetailListParams
    >({
      query: (params) => {
        return {
          url: 'receivedDataList',
          params,
        }
      },
      providesTags: () => [{ type: 'DataStatusDetail' }],
    }),
  }),
})

/**
 * データ利用用Hooks
 */
export const { useGetDataStatusListQuery, useGetDataStatusDetailListQuery } =
  dataStatusEndpoints
