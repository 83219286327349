import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserEditForm } from '../components/domain/UserEditForm'
import { useAddUserMutation } from '../ducks/users/usersApi'
import { UserEditData } from '../ducks/users/usersInterface'

export const UserAddPage = () => {
  const navigate = useNavigate()
  const [addUser] = useAddUserMutation()

  // ユーザー登録時処理
  const handleSubmit = useCallback(
    (formData: UserEditData) => {
      addUser(formData)
      navigate('/users')
    },
    [addUser, navigate]
  )

  return <UserEditForm onSubmitForm={handleSubmit} />
}
