import { Auth0ContextInterface } from '@auth0/auth0-react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState } from './authInterface'

/**
 * 認証情報の初期状態
 */
const initialState: AuthState = { getAccessTokenSilently: undefined }

/**
 * 認証情報のSlice
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // useAuth0のgetAccessTokenSilentlyを渡す（ヘッダーに含める際にStoreから参照するしか無い）
    setAccessTokenSilently: (
      state,
      action: PayloadAction<Auth0ContextInterface['getAccessTokenSilently']>
    ) => {
      state.getAccessTokenSilently = action.payload
    },
  },
})

/**
 * 認証情報のAction
 */
export const { setAccessTokenSilently } = authSlice.actions

/**
 * 認証情報のReducer
 */
export default authSlice.reducer
