import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import DashboardIcon from '@mui/icons-material/Dashboard'
import InfoIcon from '@mui/icons-material/Info'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

// サイドバーの幅(px)
const sidebarWidth = 240

/**
 * アプリケーションのサイドバー（グローバルメニュー）
 */
export const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sidebarWidth,
        [`& .MuiDrawer-paper`]: { width: sidebarWidth },
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={NavLink} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="ダッシュボード" />
        </ListItem>
        <ListItem button component={NavLink} to="/users">
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="ユーザー一覧" />
        </ListItem>
        <ListItem button component={NavLink} to="/information">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="お知らせ一覧" />
        </ListItem>
        <ListItem button component={NavLink} to="/dataStatus">
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          <ListItemText primary="受信状況確認" />
        </ListItem>
      </List>
    </Drawer>
  )
}
